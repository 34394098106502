<template>
    <div class="dosiin_page-min-height dosiin_page-max-width dosiin_padding-header-cat-top">
        <div class="dosiin_wrapper-bar dosiin_mb-16">
            <Breadcrumbs
                title="Thương hiệu bán chạy"
                :breadcumbs="breadcumbs"
            />
            <div class="dosiin-tab-bar-pc">
                <div class="tab-bar-pc-content">
                    <div class="title-page d-flex align-items-center justify-content-between">
                        <div class="title-all-categories">Thương hiệu bán chạy</div>
                        <div class="tab-bar-content-quantity">
                            <span class="quantity" v-text="state.brandsBestSelling.length"> </span>
                            <span class="text-quantity">Thương hiệu bán chạy</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Intersection
            @onIntersection="onIntersectionBrandBestselling()"
        >
            <BrandHorizonRanking 
                title="Thương hiệu bán nhiều"
                link="/brands-bestselling"
                :brands="state.brandsBestSelling"
                slideTo="30"
            />

        </Intersection>

        <section class="page-section">

            <Intersection
                @onIntersection="onIntersectionBrandSugestion()"
            >
                <BrandsSuggestHorizon 
                    title="Thương hiệu bạn có thể biết"
                    gridClass="grid-20"
                    :brands="state.brandsSugestion"
                    :options="{
                        slidesPerView : 4,
                        slidesPerGroup : 4,
                        spaceBetween   :9,
                        navigation: {
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        },
                    }"
                />
            </Intersection>

        </section>
    </div>

</template>

<script>
    import BrandsSuggestHorizon from '@/components/sliders/BrandsSuggestHorizon';
    import BrandHorizonRanking from '@/components/brand/BrandHorizonRanking';
    import { inject} from '@vue/runtime-core';

    export default {
        components:{
            BrandsSuggestHorizon,
            BrandHorizonRanking
        },
        setup() {
            const state = inject('state');
            const onIntersectionBrandBestselling = inject('onIntersectionBrandBestselling');
            const onIntersectionBrandSugestion = inject('onIntersectionBrandSugestion');

            return {
                state,
                onIntersectionBrandBestselling,
                onIntersectionBrandSugestion
            }
        },
    }
</script>

<style scoped>
    .brand-item .brand-item_img-wrapper img {
        height: 100%;
    }
    
</style>