<template>
    <main class="home-new-brand">
        <BackHeader>
            <template v-slot:center>
                <h2 class="text-grey-lg title-header-category">
                    Thương hiệu bán chạy
                </h2>
            </template>
            <template v-slot:right>
                <div class="back-header-right-side">
                    <router-link :to="{ name : 'search'}" class="search-icon dosiin_search-header-btn">
                        <i class="dsi dsi-search"></i>
                    </router-link>
                </div>
            </template>
        </BackHeader>

        <hgroup class="product-page-header_wrap">
            <h1 class="title">Thương hiệu bán chạy</h1>
            <h2 class="subtitle">Các thương hiệu bán chạy của Việt Nam</h2>
        </hgroup>
        <div 
            ref="brandsBox"
        >
            <section class="brand-shop-page-section brand-shop-filter-tabs" id="dosiin_brand-shop-filter-tabs">
                <div id="dosiin_tab-new-brand-new">
                    <Intersection
                        @onIntersection="onIntersectionBrandBestselling()"
                    >
                        <BrandVerticalRanking 
                            title="Top thương hiệu"
                            link="/brands-bestselling"
                            :brands="state.brandsBestSelling"
                            :bestSelling="true"
                        />
                    </Intersection>
                    
                </div>
            </section>
            
            <Intersection
                @onIntersection="onIntersectionProductFavorite()"
            >
                <ProductsSliderHorizon 
                    wrapperClass="page-section"
                    title="Sản phẩm bán chạy"
                    gridClass="grid-33"
                    :products="state.productsBestSelling"
                    :options="{
                        slidesPerView : 2.5,
                        slidesPerGroup : 2,
                        spaceBetween   :9,
                    }"
                />
            </Intersection>
        </div>
    </main>
</template>

<script>
    import BackHeader from '@/components/common/BackHeader';
    import BrandVerticalRanking from '@/components/brand/BrandVerticalRanking';
    import ProductsSliderHorizon from '@/components/sliders/ProductsHorizon';
    import { inject} from '@vue/runtime-core';

    export default {
        components:{
            BackHeader,
            BrandVerticalRanking,
            ProductsSliderHorizon
        },
        setup() {
            const state = inject('state');
            const onIntersectionBrandBestselling = inject('onIntersectionBrandBestselling');
            const onIntersectionProductFavorite = inject('onIntersectionProductFavorite');

            return {
                state,
                onIntersectionBrandBestselling,
                onIntersectionProductFavorite
            }
        },
    }
</script>